import React from 'react';
import { useStaticQuery, graphql, } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Whoarewe = ({ data }) => {

  const { allContentfulWhoarewePage } = useStaticQuery(graphql`
    query {
      allContentfulWhoarewePage(filter: {pageName: {ne: "default"}}) {
        edges {
          node {
            background {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            pageName
            childContentfulWhoarewePageOurInformationTextNode {
              ourInformation
            }
          }
        }
      }
    }
  `);
    
    return (
        <Layout queryData={ allContentfulWhoarewePage.edges[0].node }>
            <SEO title="whoweare" />
            <div id="WhoAmIContentElem" >
                <h1>{ allContentfulWhoarewePage.edges[0].node.pageName }</h1>
                <p style={{textAlign:'justify', textJustify:'inter-word'}}>
                { allContentfulWhoarewePage.edges[0].node.childContentfulWhoarewePageOurInformationTextNode.ourInformation }
                </p>
            </div>
        </Layout>
    )
}

export default Whoarewe;